.profile-dropdown {
  margin-left: 30px;
  position: relative;
  cursor: pointer;
  padding: 8px;

  &:hover {
    .profile-dropdown__options {
      opacity: 1;
      transform: translateY(-5px);
    }
  }

  &__circle {
    display: flex;
  }

  &__avatar {
    width: 46px;
    height: 46px;
    border-radius: 50%;
  }

  &__arrow {
    margin-left: -10px;
  }

  &__options {
    position: absolute;
    background-color: #ececec;
    padding: 7px;
    border-radius: 5px;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.05);
    width: 120px;
    right: 20px;
    margin-top: 10px;
    z-index: 2;
    opacity: 0;
    transform: translateY(-300px);
    // transition: all 400ms cubic-bezier(0, 0.3, 0.69, 1.91);
    transition: all 400ms cubic-bezier(0, 0.3, 0.69, 1.0);
  }

  &__info {
    border-bottom: 1px solid #dcdde0;
    background-color: #fff;
    border-radius: 4px;
    line-height: 20px !important;
  }

  &__item {
    display: block;
    padding: 10px;
    line-height: 10px;
  }

  &__link {
    transition: all ease 0.3s;
    margin-top: 5px;
    &:hover {
      color: #69b1ff;
    }
  }
}
